export const formatWhatsAppNumber = (input) => {
  let cleanNumber = input.startsWith("+") ? "+" : "";
  cleanNumber += input.slice(cleanNumber.length).replace(/\D/g, "");

  if (cleanNumber === "+") return cleanNumber;

  cleanNumber = cleanNumber.replace(/^\+?0+/, "+");

  if (cleanNumber.length > 3) {
    if (cleanNumber.startsWith("+54")) {
      if (!cleanNumber.startsWith("+549") && cleanNumber.length > 5) {
        cleanNumber = "+549" + cleanNumber.substring(3);
      }
    } else if (
      cleanNumber.startsWith("+52") &&
      !cleanNumber.startsWith("+521") &&
      cleanNumber.length > 4
    ) {
      cleanNumber = "+521" + cleanNumber.substring(3);
    } else if (!cleanNumber.startsWith("+")) {
      cleanNumber = "+" + cleanNumber;
    }
  }

  return cleanNumber;
};

export const formatEmail = (input) => {
  const formattedEmail = input.trim().toLowerCase();
  return formattedEmail;
};
