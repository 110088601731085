import { VerifyProps } from "../interfaces/components/verify";
import Thanks from "./Thanks";

const Verify = ({ params }: VerifyProps) => {

  return (
    <div>
      <Thanks lang={params.lang} email={params.email}  />
    </div>
  );
}

export default Verify;