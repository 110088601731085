import Pager from './Components/Navigation/Pager';
import "./App.css";
import { useRef } from 'react';
import { getParams } from './utils/page_params';
import { PagerProps } from './interfaces/components/pager';

const App = () => {
  
  const url = new URL(window.location.href);
  const pageRef = useRef<(string | {[key: string]: string;})[]>(getParams(url));

  return (
    <main role="main">
      <Pager page={pageRef.current[0] as PagerProps['page']} params={pageRef.current[1] as PagerProps['params']} />
    </main>
  );
}

export default App;
