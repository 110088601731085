import { useState, useEffect } from 'react';
import { submitSubscription } from '../services/subscription';
import { ThanksProps } from '../interfaces/components/thanks';
import { sleep } from '../utils/time';

const Thanks = ({ lang, email }: ThanksProps) => {
  const [data, setData] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await submitSubscription('email', 'email-sub', lang, email, true);
      setData(response);
      setIsLoading(false);
      await sleep(2000);
      window.location.href = window.location.origin;
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Thank you for verifying!</h1>
      <p>You're all set to recieve the Chisme AI newsletter <span role="img" aria-label="modest-smile">😌</span></p>
      <p>{data}</p>
    </div>
  );
};

export default Thanks;
