import { useEffect, useState } from "react";
import sunPath from "../assets/toggle/sun.svg";
import moonPath from "../assets/toggle/moon.svg";

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    if (document.documentElement.classList.contains("dark")) {
      setDarkMode(true);
    }

    const sunImg = new Image();
    const moonImg = new Image();

    let imagesToLoad = 2;

    const imageLoaded = () => {
      imagesToLoad -= 1;
      if (imagesToLoad === 0) {
        setImagesLoaded(true);
      }
    };

    sunImg.onload = imageLoaded;
    moonImg.onload = imageLoaded;

    sunImg.src = sunPath;
    moonImg.src = moonPath;
  }, []);

  if (!imagesLoaded) {
    return <div>...</div>;
  }

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
  };

  return (
    <button onClick={toggleDarkMode}>
      {darkMode ? (
        <img className="w-9 h-9 pt-1" src={sunPath} />
      ) : (
        <img className="w-9 h-9 pt-1" src={moonPath} />
      )}
    </button>
  );
};

export default DarkModeToggle;
