import { useState } from 'react';
import Home from '../Home';
import Verify from '../Verify';
import { PagerProps } from '../../interfaces/components/pager';

const Pager = ({ page, params }: PagerProps) => {
  const [activeComponent] = useState<'/' | 'Home' | 'Verify' | 'home' | 'verify'>(page);

  // const handleClick = (component: '/' | 'Home' | 'Verify') => {
  //   setActiveComponent(component);
  // };

  return (
    <div>
      {/* <button onClick={() => handleClick('home')}>Home</button>
      <button onClick={() => handleClick('verify')}>Verify</button> */}
      {['home', 'Home', '/'].includes(activeComponent) && <Home />}
      {['verify', 'Verify'].includes(activeComponent) && <Verify params={params} />}
    </div>
  );
};

export default Pager;
