import { translations } from "../../interfaces/interface";
import logo from "../../assets/logo.png";
import { NavbarProps } from "../../interfaces/interface";
import DarkModeToggle from "../DarkModeToggle";

const Navbar = ({ language, toggleLanguage }: NavbarProps) => {
  const t = translations[language];

  return (
    <nav className="bg-transparent bg-white dark:bg-gray-800  text-black py-6">
      <div className="container mx-auto md:w-9/12 w-11/12 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Chisme.ai Logo" className="md:h-8 h-7 mr-1" />
        </div>
        <div className="flex items-center">
          <div className="">
            <DarkModeToggle />
          </div>
          <div className="border-r-2 border-gray-200 h-5 ml-1 mr-2"></div>
          <button
            aria-label={t.ariaLabel}
            onClick={toggleLanguage}
            className="underline underline-offset-4 font-medium text-md text-blue-600 dark:text-blue-500 mb-2 ml-1"
          >
            {language === "es" ? "English" : "Español"}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
