import React, { useEffect, useState } from "react";
import Navbar from "./Navigation/Navbar";
import phoneWhatsApp from "../assets/Chisme-WhatsApp.png";
import phoneNewsletter from "../assets/Newsletter-Phone.png";
import { translations } from '../interfaces/interface'
import Forms from "./Landing/Forms";

function Home() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [language, setLanguage] = useState("es");

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.maxTouchPoints > 0
    );
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMousePosition({ x, y });
  };

  const calculateMovementWithRotation = (factor: number, rotation: string) => {
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    const moveX = (mousePosition.x - centerX) * factor;
    const moveY = (mousePosition.y - centerY) * factor;
    return `translate(${moveX}px, ${moveY}px) rotate(${rotation})`;
  };

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "es" ? "en" : "es"));
  };

  const t = translations[language];

  return (
    <div
      className="flex flex-col bg-indigo-50 dark:bg-gray-800 mx-auto max-w-full text-white min-h-screen"
      onMouseMove={!isTouchDevice() ? handleMouseMove : undefined}
    >
      <Navbar language={language} toggleLanguage={toggleLanguage} />
      <div className="container mx-auto w-11/12 flex flex-col justify-between items-center text-black mt-8 h-11/12 md:flex-row md:w-9/12 md:h-1/2">
        <Forms language={language} />
        <div className="flex justify-center items-center md:flex-1 relative mt-4 md:mt-10 md:p-0 p-10">
          <div className="absolute w-80 h-80 bg-indigo-300 dark:bg-slate-300 rounded-full -z-60"></div>
          <img
            src={phoneNewsletter}
            alt={t.landingImgAltNewsletter}
            className="md:h-96 h-72 relative z-10"
            style={{ transform: calculateMovementWithRotation(0.01, "-6deg") }}
          />
          <img
            src={phoneWhatsApp}
            alt={t.landingImgAltWhatsApp}
            className="md:h-96 h-72 relative z-10 mt-10"
            style={{ transform: calculateMovementWithRotation(0.01, "6deg") }}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
