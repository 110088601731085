import { PostResultTypes } from "../interfaces/components/postResult";

// const BACKEND = "";
const BACKEND = "/v1/subscribe";

export const submitSubscription = async (id: string, type: string, lang: string, inputValue: string, isEmail: boolean) => {
  
  let data = { }; // if we use name then add prop name: inputNameValue
  let inputObj = isEmail ? { email: inputValue } : { phone: inputValue }
  data = {...data, ...inputObj};

  const date = new Date().toISOString();
  const requestBody = {
    requestType: `${type}${lang === "es" ? "-es" : ""}`,
    data,
    date,
    lang
  };
  
  console.log(`requestBody: ${JSON.stringify(requestBody)}`);

  try {
     const result = await fetch(BACKEND, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });
    const res = await result.json();
    console.info(res);
    // alert(res);
    return res as string;
  } catch (error: any) {
    console.error(error.body);
    alert('An error occurred. Please try again later.');
    throw error;
  }
}

const postToLambda = async (optionsResult: Response | undefined, requestBody: PostResultTypes) => {
  console.error(`Lambda not available (HTTP ${optionsResult?.status})`);
          
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for half a second
    
    const postResult2nd = await fetch(BACKEND, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const res2 = await postResult2nd.json();
    console.info(res2);
    return res2 as string;
    // alert('Lambda not available. Please try again later.');
    // throw new Error('Lambda not available');
}

// This function will first check AWS cold start, then wait half a second and execute POST
export const submitSubscriptionColdStart = async (id: string, type: string, lang: string, inputValue: string, isEmail: boolean) => {
  let data = {};
  let inputObj = isEmail ? { email: inputValue } : { phone: inputValue };
  data = { ...data, ...inputObj };

  const date = new Date().toISOString();
  const requestBody = {
      requestType: `${type}${lang === 'es' ? '-es' : ''}`,
      data,
      date,
      lang,
  };

  console.log(`requestBody: ${JSON.stringify(requestBody)}`);

  let optionsResult: Response | undefined;
  try {
      // Send an OPTIONS request to check Lambda availability
      optionsResult = await fetch(BACKEND, {
          method: 'OPTIONS',
          headers: {
              'Content-Type': 'application/json',
          },
      });

      // If successful, proceed with the POST request
      if (optionsResult.status === 200) {
          
          const postResult = await fetch(BACKEND, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
          });

          const res = await postResult.json();
          console.info(res);
          return res as string;

      } else { // Lambda likely off, so wait half a second, then POST
          await postToLambda(optionsResult, requestBody);
      }
  } catch (error: any) {
      console.error(error);
      try {
        await postToLambda(optionsResult, requestBody);
      } catch (error) {
        alert('An error occurred. Please try again later.');
        return 'An error occurred. Please try again later.'
      }
  }
};
