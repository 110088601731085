import React, { useState } from "react";
import { translations } from "../../interfaces/interface";
import { formatEmail } from "../../utils/utils";
import { NavbarProps } from "../../interfaces/interface";
import email from "../../assets/email-icon.svg";
import whatsAppSvg from "../../assets/whatsapp.svg";
import carrot from "../../assets/carrot.svg";
import { submitSubscription } from "../../services/subscription";

const Forms = ({ language }: NavbarProps) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [contactInfo, setContactInfo] = useState({
    phone: countryCode,
    email: "",
  });

  const toggleEmailInputVisibility = () => setShowEmailInput(!showEmailInput);

  const handleInputChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
      .slice(countryCode.length)
      .replace(/\D/g, "");

    setContactInfo({
      ...contactInfo,
      phone: `${countryCode}${inputValue}`,
      email: "",
    });
  };

  const handleInputChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const formattedValue = formatEmail(value);
    setContactInfo({ ...contactInfo, email: formattedValue, phone: "" });
  };

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCountryCode = e.target.value;

    let currentPhone = contactInfo.phone.replace(/^\+\d+/, "");

    setCountryCode(newCountryCode);
    setContactInfo({
      ...contactInfo,
      phone: `${newCountryCode}${currentPhone}`,
      email: "",
    });
  };

  const handleSubmitPhone = async (e: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitted) return;
    e.preventDefault();
  
    let subscriptionType = "whatsapp-sub";
    let contactValue = "";
    let requiresVerification = false;
  
    if (!contactInfo.phone) {
      setErrorMsg(t.error.phone.emptyPhone);
      return;
    }
  
    if (contactInfo.phone.length < 10) {
      setErrorMsg(t.error.phone.invalidPhone);
      return;
    }
  
    if (contactInfo.phone.startsWith("+1") && contactInfo.phone.length !== 12) {
      setErrorMsg(t.error.phone.invalidAmericaNumber);
      return;
    }
  
    if (contactInfo.phone.startsWith("+52") && contactInfo.phone.length !== 14) {
      setErrorMsg(t.error.phone.invalidMexicoNumber);
      return;
    }
  
    if (contactInfo.phone.startsWith("+503") && contactInfo.phone.length !== 12) {
      setErrorMsg(t.error.phone.invalidElSalvadorNumber);
      return;
    }
  
    contactValue = contactInfo.phone;
  
    setErrorMsg("");
    setIsSubmitted(true);
  
    try {
      await submitSubscription("whatsapp", subscriptionType, language, contactValue, requiresVerification);
      
      const successMessage = t.successMessage.replace("{contactType}", t.whatsapp);
      setSuccessMsg(successMessage);
    } catch (error) {
      console.error(error);
      setErrorMsg(t.error.generic);
    } finally {
      setIsSubmitted(false);
    }
  };
  
  const handleSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitted) return;
    e.preventDefault();
  
    if (!showEmailInput || !contactInfo.email) {
      setErrorMsg(t.error.email.emptyEmail);
      return;
    }
  
    if (!contactInfo.email.includes("@")) {
      setErrorMsg(t.error.email.invalidEmail);
      return;
    }
  
    setIsSubmitted(true);
  
    try {
      await submitSubscription(
        "email",
        "verification",
        language,
        contactInfo.email,
        true
      );
      
      const successMessage = t.successMessage.replace("{contactType}", t.email);
      setSuccessMsg(successMessage);
    } catch (error) {
      console.error(error);
      setErrorMsg(t.error.generic);
    } finally {
      setIsSubmitted(false);
    }
  };
  

  const options = [
    { value: "+1", label: "(US)" },
    { value: "+52", label: "(MX)" },
    { value: "+54", label: "(AR)" },
    { value: "+57", label: "(CO)" },
    { value: "+56", label: "(CL)" },
    { value: "+58", label: "(VE)" },
    { value: "+51", label: "(PE)" },
    { value: "+595", label: "(PY)" },
    { value: "+507", label: "(PA)" },
    { value: "+505", label: "(NI)" },
    { value: "+506", label: "(CR)" },
    { value: "+503", label: "(SV)" },
    { value: "+593", label: "(EC)" },
    { value: "+502", label: "(GT)" },
    { value: "+504", label: "(HN)" },
    { value: "+598", label: "(UY)" },
    { value: "+34", label: "(ES)" },
    { value: "+55", label: "(BR)" },
  ];

  const t = translations[language];

  return (
    <div className="md:flex-1 mb-6 md:mb-0">
      <h1 className="text-slate-700 dark:text-slate-100 mt-2 text-sm font-semibold animate-pulse">
        {t.subTitle}
      </h1>
      <h1 className="text-5xl font-bold text-slate-700 dark:text-slate-100 mr-4">{t.title}</h1>
      <p className="text-slate-500 dark:text-slate-300 mt-4 text-sm pr-4">{t.subheading}</p>

      {successMsg ? (
        <div className="my-4 p-2 border-2 fade-in border-green-500 rounded-lg">
          <div className="text-start fade-in text-sm text-green-500">
            {successMsg}
          </div>
        </div>
      ) : errorMsg ? (
        <div className="my-4 p-2 border-2 fade-in border-red-500 rounded-lg">
          <div className="text-start fade-in text-sm text-red-500">
            {errorMsg}
          </div>
        </div>
      ) : null}

      <div className="mx-auto my-auto w-full mt-4">
        <form
          noValidate
          onSubmit={handleSubmitPhone}
          className="flex rounded-lg static"
        >
          <div className="relative flex items-center">
            {" "}
            <select
              className="appearance-none bg-white py-4 pl-3 pr-7 border rounded-l-lg rounded-r-none shadow-md text-md cursor-pointer"
              value={countryCode}
              onChange={handleCountryCodeChange}
              disabled={isSubmitted}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700">
              <img
                src={carrot}
                alt="dropdown"
                className="fill-current h-6 w-6"
              />{" "}
            </div>
          </div>

          <input
            className="bg-white py-4 text-grey-darkest w-full outline-none text-md pl-4 border rounded-l-none rounded-r-lg shadow-md placeholder:text-slate-400 placeholder:text-sm"
            type="tel"
            placeholder={t.placeholder.whatsapp}
            value={contactInfo.phone || ""}
            onChange={handleInputChangePhone}
            disabled={isSubmitted}
            required={showEmailInput ? false : true}
            minLength={12}
            maxLength={17}
          />

          <div className="flex items-center justify-center absolute md:right-2/4 right-2 mt-3 pr-4 md:pr-2 font-bold h-10">
            <button className="flex items-center justify-center px-4 py-3 bg-blue-600 text-white text-sm rounded-lg shadow-lg font-medium hover:bg-blue-700 transition-colors duration-150 mb-1.5">
              <img
                src={whatsAppSvg}
                alt="WhatsApp"
                className="w-4 h-4 md:mr-1"
              />
              <div className="md:block hidden">
                {isSubmitted ? t.buttonDisabled : t.button}
              </div>
            </button>
          </div>
        </form>

        <div className="mt-2">
          <span
            className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
            onClick={toggleEmailInputVisibility}
          >
            {t.link}
          </span>
        </div>
      </div>

      {showEmailInput && (
        <div className="mx-auto my-auto w-full mt-2">
          <form
            noValidate
            onSubmit={handleSubmitEmail}
            className="flex rounded-lg static"
          >
            <input
              className="bg-white text-grey-darker py-4 font-normal text-grey-darkest border w-full px-2 outline-none text-md rounded-lg shadow-md placeholder:text-slate-400 placeholder:text-sm"
              type="email"
              placeholder={t.placeholder.email}
              value={contactInfo.email || ""}
              onChange={handleInputChangeEmail}
              disabled={isSubmitted}
              required={!showEmailInput ? false : true}
            />
            <div className="flex items-center justify-center absolute md:right-2/4 right-2 mt-3 rounded pr-4 md:pr-2 font-bold h-10">
              <button className="flex items-center justify-center px-4 py-3 bg-blue-600 text-white text-sm rounded-lg shadow-lg font-medium hover:bg-blue-700 transition-colors duration-150 mb-1.5">
                <img src={email} alt="WhatsApp" className="w-4 h-4 md:mr-1" />
                <div className="md:block hidden">
                  {isSubmitted ? t.buttonDisabled : t.button}
                </div>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Forms;
