export type ContactType = "whatsapp" | "email";

export interface NavbarProps {
  language: string;
  toggleLanguage?: () => void;
}

export interface Translation {
  whatsapp: string;
  email: string;
  placeholder: {
    whatsapp: string;
    email: string;
  };
  contactToggle: string[];
  languageToggle: string;
  button: string;
  buttonDisabled: string;
  error: {
    generic: string;
    email: {
      emptyEmail: string;
      invalidEmail: string;
    };
    phone: {
      emptyPhone: string;
      invalidPhone: string;
      invalidAmericaNumber: string;
      invalidMexicoNumber: string;
      invalidElSalvadorNumber: string;
    };
  };
  successMessage: string;
  title: string;
  subTitle: string;
  subheading: string;
  link: string;
  ariaLabel: string;
  landingImgAltWhatsApp: string;
  landingImgAltNewsletter: string;
}

export type Translations = {
  [key: string]: Translation;
};

export const translations: Translations = {
  en: {
    whatsapp: "WhatsApp",
    email: "Email",
    placeholder: {
      whatsapp: "e.g. +50355559999",
      email: "e.g. ben@example.com",
    },
    contactToggle: ["WhatsApp", "Email"],
    languageToggle: "English",
    button: "Subscribe",
    buttonDisabled: "In Progress...",
    error: {
      generic: "An error occurred during form submission. Please try again.",
      email: {
        emptyEmail: "Email address cannot be empty.",
        invalidEmail: "Please type a valid email."
      },
      phone: {
        emptyPhone: "Phone number cannot be empty.",
        invalidPhone: "Please enter a valid number.",
        invalidAmericaNumber: "Error: Format should be +1 555 123 4567.",
        invalidMexicoNumber: "Error: Format should be +52 1 555 123 4567.",
        invalidElSalvadorNumber: "Error: Format should be +503 2345 6789."
      }
    },
    successMessage: "Your {contactType} has been submitted successfully!",
    title: "Stay Ahead with Daily Tech News via WhatsApp & Email.",
    subTitle: "⚡️ Powered by AI",
    subheading:
      "Get your daily tech fix with quick audio (3-5 min) on WhatsApp and articles in your inbox. Free!",
    link: "or sign up to our newsletter!",
    ariaLabel: "Change language",
    landingImgAltWhatsApp: "Cell Phone WhatsApp image",
    landingImgAltNewsletter: "Cell Phone Newsletter image",
  },
  es: {
    whatsapp: "WhatsApp",
    email: "Boletín Electrónico",
    placeholder: {
      whatsapp: "e.g. +50355559999",
      email: "e.g. ben@ejemplo.com",
    },
    contactToggle: ['"WhatsApp"', "Correo Electrónico"],
    languageToggle: "Español",
    button: "Suscríbete",
    buttonDisabled: "En Progreso...",
    error: {
      generic:
        "Se produjo un error durante el envío del formulario. Inténtalo de nuevo.",
      email: {
        emptyEmail: "La dirección de correo electrónico no puede estar vacía.",
        invalidEmail: "Por favor escriba un correo electrónico válido."
      },
      phone: {
        emptyPhone: "El número de teléfono no puede estar vacío.",
        invalidPhone: "Por favor, ingresa un número válido.",
        invalidAmericaNumber: "Error: El formato debe ser +1 555 123 4567.",
        invalidMexicoNumber: "Error: El formato debe ser +52 1 555 123 4567.",
        invalidElSalvadorNumber: "Error: El formato debe ser +503 2345 6789."
      }
    },
    successMessage: "Tu {contactType} ha sido enviado con éxito!",
    title: "Mantente informado con noticias tech por WhatsApp y correo.",
    subTitle: "⚡️ Activado por IA",
    subheading:
      "Consigue tu dosis diaria de tecnología con audio rápido (3-5 min) en WhatsApp y artículos en tu boletín electrónico. ¡Gratis!",
    link: "¡o suscríbete a nuestro boletín!",
    ariaLabel: "Cambiar idioma",
    landingImgAltWhatsApp: "WhatsApp para celular imagen",
    landingImgAltNewsletter: "Imagen del boletín de teléfonos móviles",
  },
};
